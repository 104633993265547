<template>
  <b-container fluid>
    <b-row class="py-2">
      <b-button class="btn-info" @click="addNew()">
        <b-icon icon="plus" aria-hidden="true"></b-icon>
        Добавить
      </b-button>
    </b-row>
    <b-row>
      <b-table striped hover :items="checklistDiseaseOptions" :fields="fields">
        <template v-slot:cell(buttons)="data">
          <h5 class="buttons">
            <nobr>
              <b-link @click="editParamFactorBandValue(data.item)">
                <b-icon variant="info" icon="pencil" size="lg"></b-icon>
              </b-link>
              &nbsp;
              <b-link @click="deleteRubricatorValue(data.item.oldDiseaseId)">
                <b-icon variant="info" icon="x-circle" size="lg"></b-icon>
              </b-link>
            </nobr>
          </h5>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <b-modal :id="'checklistDisease'" hide-footer size="lg" title="Выбор патологии">

<!--        <FactorBandEdit @save-success="afterSave" v-bind:analit-id="this.analitId" v-bind:factor-band="currentFactorBand"-->
<!--                        v-bind:factor-value="currentFactorValue" v-bind:factor-text="currentFactorText" v-bind:param-type="this.paramType"></FactorBandEdit>-->
<!--        -->


        <b-container fluid>
          <b-row class="newAttr pt-2">
            <b-col cols="3"><label for="factorId">Патологии</label></b-col>
            <b-col cols="9">
              <b-form-select v-if="currentChecklistDisease.oldDiseaseId != null" id="factorId" v-model="currentChecklistDisease.diseaseId" :options="factorOptions" required :select-size="1"></b-form-select>
              <treeselect v-else name="param" id="param" placeholder=""
                          multiple clearable searchable required
                          open-on-click close-on-select :flat="true"
                          :noResultsText="'Не найдено...'"
                          :noOptionsText="'Список опций пуст'"
                          :options="factorOptions | searchQueryFilter(currentChecklistDisease.diseasesIds, childrenSearchQuery, checklistDiseaseOptions)"
                          :max-height="200"
                          :normalizer="normalizeTreeselect"
                          v-model="currentChecklistDisease.diseasesIds" style="font-size: 15px!important;" @search-change="childrenSearchChange">
              </treeselect>
            </b-col>
          </b-row>


          <b-row class="newAttr pt-2">
            <b-col cols="3"><label for="priorityReport">Приоритет</label></b-col>
            <b-col cols="9"><b-form-input id="priorityReport" type="number" required  v-model="currentChecklistDisease.priorityReport" autocomplete="off"></b-form-input></b-col>
          </b-row>
          <b-row class="newAttr pt-2">
            <b-button variant="info" @click="saveChecklistDisease()">Сохранить</b-button>
          </b-row>

        </b-container>
      </b-modal>
    </b-row>

  </b-container>
</template>

<script>
import {host} from "@/constants";

// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'


export default {
  props: ["checklistId"],
  name: "CheckListDiseaseList",
  components: {
    Treeselect
  },
  data() {
    return {
      currentChecklistDisease: {
        checklistId: this.checklistId,
        oldDiseaseId: null,
        diseaseId: null,
        diseaseName: '',
        priorityReport: 1,
        diseasesIds: []
      },
      factorOptions: [],
      childrenSearchQuery: '',
      checklistDiseaseOptions: [],
      fields: [
        {
          key: 'diseaseName',
          label: 'Наименование'
        },
        {
          key: 'priorityReport',
          label: 'Приоритет',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      normalizeTreeselect(node) {
        return {
          id: node.value,
          label: node.text
        }
      }
    }
  },
  filters: {

    searchQueryFilter: function (options, selected, searchQuery, checklistDiseaseOptions) {
      console.log('searchQueryFilter' + searchQuery)
      if (!searchQuery || searchQuery.length <= 2) {
        return []
      }

      let result = []

      result = options.filter((el, index, []) => {
        return (selected.find(sel => (sel == el.value)) || ((searchQuery) && (el.text).toLowerCase().includes(searchQuery.toLowerCase()) ))
            && !checklistDiseaseOptions.find(el1 => el1.oldDiseaseId == el.value)
      })

      return result
    }
  },
  methods: {
    saveChecklistDisease() {
      let method = 'POST'
      if (this.currentChecklistDisease.oldDiseaseId != null) {
        method = 'PUT'
      }

      fetch(host + '/rest/checklist/' + this.checklistId + '/diseases', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.currentChecklistDisease),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            // if (json.resultCode == 0) {
            this.fetchFactorList()
            this.$bvModal.hide('checklistDisease')

            // } else {
            //
            //   this.error = json.resultCode + ' ' + json.message;
            // }
          })
          .catch(error => {
            this.fetchFactorList()
            this.$bvModal.hide('checklistDisease')

          })
    },
    addNew() {
      this.currentChecklistDisease = {
        checklistId: this.checklistId,
        oldDiseaseId: null,
        diseaseId: null,
        diseaseName: '',
        priorityReport: 1,
        diseasesIds: []
      }
      this.$bvModal.show('checklistDisease')
    },
    editParamFactorBandValue(item) {
      this.currentChecklistDisease = item
      this.$bvModal.show('checklistDisease')
    },
    childrenSearchChange(searchQuery, instanceId) {
      console.log('childrenSearchChange' + searchQuery)
      this.childrenSearchQuery = searchQuery
    },
    deleteRubricatorValue(diseaseId) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить параметр?')) {
        this.loading = true;
        fetch(host + '/rest/checklist/' + this.checklistId + '/diseases/' + diseaseId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {


                console.log('Delete Success ' + json)

              } else {

                console.log('Delete Failed ' + json)
                this.error = json.message;
              }
              this.loading = false
              this.fetchFactorList()
            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    fetchFactorList() {
      fetch(host + '/rest/checklist/' + this.checklistId + '/diseases', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.checklistDiseaseOptions = json.checkListDiseases
            console.log(JSON.stringify(json))
          })
    },
    fetchDicts() {
      console.log('fetchDicts')
      fetch(host + '/rest/factors/Disease', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.factorOptions = json.options
          })
    }
  },
  mounted() {
    console.log('CheckListDiseaseList = ' + this.checklistId)
    this.fetchFactorList()
    this.fetchDicts()
  }
}
</script>

<style scoped>

</style>